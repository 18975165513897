import React from 'react';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import ReactQuill from "react-quill";

const PartnerEditModal = ({ partner, onSavePartner, show, onClose, appsIndexes, imageInputRef }) => {
  const [detailedDescription, setDetailedDescription] = React.useState(partner.detailedDescription || '');

  if (!partner.advantages) {
    partner.advantages = [];
  }
  while (partner.advantages.length < 3) {
    partner.advantages.push({title: '', text: ''});
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const editedPartner = { ...partner, detailedDescription };

    for (let [key, value] of formData.entries()) {
      if (key === 'image') {
        editedPartner[key] = URL.createObjectURL(value);
      } else if (key.startsWith('advantageTitle_') || key.startsWith('advantageText_')) {
        const index = key.split('_')[1];
        if (!editedPartner.advantages) {
          editedPartner.advantages = [];
        }
        if (!editedPartner.advantages[index]) {
          editedPartner.advantages[index] = {};
        }
        const property = key.split('_')[0].replace('advantage', '').toLowerCase();
        editedPartner.advantages[index][property] = value;
      } else {
        editedPartner[key] = value;
      }
    }

    onSavePartner(editedPartner);
  };

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="editForm" onSubmit={handleSubmit}>
          <Container>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={partner.name}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Age:</Form.Label>
                  <Form.Control
                    type="text"
                    name="age"
                    defaultValue={partner.age}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Amount:</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    defaultValue={partner.amount}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Rate:</Form.Label>
                  <Form.Control
                    type="text"
                    name="rate"
                    defaultValue={partner.rate}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Action:</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    defaultValue={partner.description}
                  />
                </Form.Group>
              </Col>
            </Row>
            {partner.advantages.map((advantage, index) => (
              <React.Fragment key={index}>
                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      <Form.Label>Advantage Title {index + 1}:</Form.Label>
                      <Form.Control
                        type="text"
                        name={`advantageTitle_${index}`}
                        defaultValue={advantage.title}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Advantage Text {index + 1}:</Form.Label>
                      <Form.Control
                        type="text"
                        name={`advantageText_${index}`}
                        defaultValue={advantage.text}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Detailed Description:</Form.Label>
                  <ReactQuill
                    value={detailedDescription}
                    onChange={setDetailedDescription}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group>
                  <Form.Label>Current Image:</Form.Label>
                  <img src={partner.image} alt={partner.name} width="100%" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Upload New Image:</Form.Label>
                  <Form.Control
                    ref={imageInputRef}
                    type="file"
                    label="Choose new image"
                    accept="image/png"
                  />
                </Form.Group>
              </Col>
            </Row>

            {Object.keys(partner)
              .filter((key) => key.startsWith("link_"))
              .sort()
              .map((key) => (
                <Row className="mb-3" key={key}>
                  <Col>
                    <Form.Group>
                      <Form.Label>{appsIndexes[key.replace('link_', '')]}:</Form.Label>
                      <Form.Control type="text" name={key} defaultValue={partner[key]} />
                    </Form.Group>
                  </Col>
                </Row>
              ))}

            <div className="button-container">
              <Button type="submit">Save</Button>
            </div>
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PartnerEditModal;
