import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({ show, onClose, onConfirm, action, actionDescription }) => {

  const handleConfirm = () => {
    if (action) {
      action();
    }
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  };
  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={handleCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to {actionDescription}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm}>
          Yes
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
