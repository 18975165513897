import React, { useState } from 'react';

const AuthWrapper = ({ children }) => {
  const [password, setPassword] = useState(localStorage.getItem('password') || '');
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    localStorage.setItem('password', inputValue);
    setPassword(inputValue);
  };

  if (password) {
    return children;
  } else {
    return (
      <div className="auth-wrapper">
        <div className="auth-container">
          <input
            type="password"
            className="auth-input"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter password"
          />
          <button className="auth-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    );
  }
};

export default AuthWrapper;
