import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Col, Form, Row, Table, Toast } from 'react-bootstrap';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const Texts = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [isLoading, setIsLoading] = useState(false);

  const [filenames, setFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [jsonContent, setJsonContent] = useState({});

  const [toast, setToast] = useState({ show: false, message: '', variant: 'success' });

  const quillRef = useRef(null);

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image'], // Include the image button in the toolbar
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };


  const password = localStorage.getItem('password');
  useEffect(() => {
    setJsonContent('');
    setSelectedFile('');

    document.title = 'App Texts';
    const fetchFilenames = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${ apiUrl }/texts.json`, {
          headers: { 'X-Password': password },
        });
        setFilenames(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching filenames:', error);
      }
    };

    fetchFilenames();
  }, [apiUrl, password]);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          if (file) {
            await handleImageUpload(file);
          }
        };
      });
    }
  }, []);

  const handleFileChange = async (e) => {
    setSelectedFile(e.target.value);

    if (e.target.value !== 'Choose...') {
      try {
        setIsLoading(true);
        setJsonContent('');
        const response = await axios.get(`${ apiUrl }/texts/${ e.target.value }`, {
          headers: { 'X-Password': password },
        });

        const ordered = Object.keys(response.data).sort().reduce(
          (obj, key) => {
            obj[key] = response.data[key];
            return obj;
          },
          {}
        );
        setJsonContent(ordered);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching text content:', error);
        setIsLoading(false);
      }
    }
  };

  const handleContentChange = (key, value) => {
    setJsonContent((prevJsonContent) => ({
      ...prevJsonContent,
      [key]: value,
    }));
  };

  const handleCloseToast = () => {
    setToast({ ...toast, show: false });
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Image = e.target.result;

      // Insert the base64 image in the editor at the current cursor position.
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      editor.insertEmbed(range.index, 'image', base64Image);
    };
    reader.readAsDataURL(file);
  };


  const saveFile = async () => {
    try {
      await axios.put(`${ apiUrl }/texts/${ selectedFile }`, jsonContent, {
        headers: { 'X-Password': password },
      });
      setToast({ show: true, message: 'File saved successfully', variant: 'success' });
    } catch (error) {
      console.error('Error saving file:', error);
      setToast({ show: true, message: 'Error saving file', variant: 'danger' });
    }
  };

  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  const handleAddNew = (e) => {
    e.preventDefault();

    if (newKey && newValue) {
      setJsonContent((prevJsonContent) => ({
        ...prevJsonContent,
        [newKey]: newValue,
      }));

      setNewKey('');
      setNewValue('');
    }
  };

  const htmlFields = ['about', 'aboutPartnersPage'];
  return (
    <div className="container mt-5">
      { isLoading && (<div className="loader-container">
        <div className="loader"></div>
      </div>) }
      <Form>
        <Form.Group controlId="fileSelect">
          <Form.Control as="select" value={ selectedFile } onChange={ handleFileChange }>
            <option>Choose...</option>
            { filenames.map((filename, index) => (
              <option key={ index }>{ filename }</option>
            )) }
          </Form.Control>
        </Form.Group>
      </Form>
      { selectedFile !== '' && (
        <>
          <Table striped bordered hover className="mt-5">
            <tbody>
            { Object.entries(jsonContent).map(([key, value], index) => (
              <tr key={ index }>
                <td className="key-column">{ key }</td>
                <td>
                  { htmlFields.indexOf(key) !== -1 ? (
                    <ReactQuill
                      ref={ quillRef }
                      value={ value }
                      onChange={ (e) => handleContentChange(key, e) }
                      modules={ quillModules }
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      value={ value }
                      onChange={ (e) => handleContentChange(key, e.target.value) }
                    />
                  ) }
                </td>
              </tr>
            )) }
            </tbody>
          </Table>
          <Form onSubmit={ handleAddNew } className="mt-5">
            <Row>
              <Col xs={ 12 } md={ 6 }>
                <Form.Group>
                  <Form.Label>New Key</Form.Label>
                  <Form.Control type="text" value={ newKey } onChange={ (e) => setNewKey(e.target.value) }/>
                </Form.Group>
              </Col>
              <Col xs={ 12 } md={ 6 }>
                <Form.Group>
                  <Form.Label>New Value</Form.Label>
                  <Form.Control type="text" value={ newValue } onChange={ (e) => setNewValue(e.target.value) }/>
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-2">
              <Button type="submit">Add Pair</Button>
            </div>
          </Form>
          <Button className="save-button" onClick={ saveFile }>
            Save
          </Button>
        </>
      ) }
      <Toast
        onClose={ handleCloseToast }
        show={ toast.show }
        delay={ 3000 }
        autohide
        style={ {
          position: 'fixed',
          top: 20,
          right: 20,
          minWidth: '200px',
        } }
      >
        <Toast.Header>
          <strong className={ `me-auto text-${ toast.variant }` }>Notification</strong>
        </Toast.Header>
        <Toast.Body>{ toast.message }</Toast.Body>
      </Toast>
    </div>
  );
};

export default Texts;
