import React from 'react';

const CountryTable = ({ countries, onCheckboxChange, setCurrencyIcon, setLanguage }) => {
  return (
    <div className="container mt-5">
      <div className="table-responsive">
        <table className="table table-bordered border table-striped table-hover mt-4">
          <thead>
          <tr>
            <th className="text-center">Title</th>
            <th className="text-center">Active</th>
            <th className="text-center">Currency</th>
            <th className="text-center">Language</th>
          </tr>
          </thead>
          <tbody>
          {countries.map((country) => (
            <tr key={ country.iso3 }>
              <td>{ country.name }</td>
              <td className="text-center custom-checkbox">
                <input
                  type="checkbox"
                  checked={ country.isActive }
                  onChange={ () => onCheckboxChange(country.id, 'isActive', !country.isActive) }
                />
              </td>
              <td className="text-center">
                <input
                  type="text"
                  value={ country.currencyIcon ?? '' }
                  onChange={ (event) => setCurrencyIcon(country.id, event.target.value) }
                />
              </td>
              <td className="text-center">
                <input
                  type="text"
                  value={ country.language ?? '' }
                  onChange={ (event) => setLanguage(country.id, event.target.value) }
                />
              </td>
            </tr>
          )) }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CountryTable;
