import React from 'react';

const Partners = ({ country }) => {
  React.useEffect(() => {
    document.title = `${country.toUpperCase()} Partners`;
  }, [country]);

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Calendar ({country})</h1>
      <p>This is the Calendar page for {country}.</p>
    </div>
  );
};

export default Partners;
